import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
//const ScriptLoaded = require("../../docs/ScriptLoaded").default;

import picLeft from "../../assets/property/pic-left-dark.png";
import picLeftOn from "../../assets/property/pic-left-on-dark.png";
import picRight from "../../assets/property/pic-right-dark.png";
import picRightOn from "../../assets/property/pic-right-on-dark.png";

import iconLike from "../../assets/property/pic-icons/like.png";
import iconSave from "../../assets/property/pic-icons/save.png";
import iconShareLink from "../../assets/property/pic-icons/share-link.png";
import iconSharePdf from "../../assets/property/pic-icons/share-pdf.png";

import bath from "../../assets/property/baths.png";

import eventsIcon from "../../assets/collections/icons/events.png";
import familyIcon from "../../assets/collections/icons/family.png";
import petsIcon from "../../assets/collections/icons/pets.png";
import sustainIcon from "../../assets/collections/icons/sustainable.png";

import ReactToPdf from "react-to-pdf";
import DatePickerArrival from "../../components/Forms/Fields/DatePickerArrival/DatePickerArrival";
import DatePickerDeparture from "../../components/Forms/Fields/DatePickerDeparture/DatePickerDeparture";
import PageHeader from "../../components/PageHeader";
import ImageWithHover from "../../components/ImageWithHover";
import {
  getStorageValue,
  isNullOrEmptyArray,
  calculateTotalNights,
  countWeekendDays,
  detectCurrency,
  isPercentage,
  isPercentageOrAmount,
} from "../../Util/general";
import { PATH_SEARCH, PATH_RESERVE } from "../../Util/constants";

import "./Property.scss";
import Button from "../../components/Buttons/Button/Button";
import Row from "../../components/Row";
import UseCreateObject from "../../Hooks/UseCreateObject.jsx";
import goBack from "../../assets/go-back.svg";
import { baseURL } from "../../core";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";

const google = window.google;

const Property = (props, propertyId) => {
  const [picIndex, setPicIndex] = useState(0);
  const dispatch = useDispatch();
  const { type, onBack } = props;
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const screenSize = localStorage.getItem("screenSize");
  const property = location.state && location.state.property;
  const ref = React.createRef();
  let prop = props;
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  useEffect(() => {
    const load = async () => {};

    load();
  }, []);
  console.log("please read property=", propertyId, props);

  console.log("current property:", property);
  const handleSend = async () => {
    try {
      setLoading(true);
      if (property) {
        setLoading(true);
        const payload = {
          title: property?.title,
          guests: property?.accommodates,
          bedrooms: property?.bedrooms,
          bathrooms: property?.bathrooms,
          overview: property?.publicDescription?.summary,
          amenities: property?.amenities,
          thumbnail: property?.picture?.thumbnail,
          propertyPictures: property?.pictures?.map((pic) => {
            return {
              thumbnail: `https://${pic?.thumbnail?.split("//")[1]}`,
              original: `https://${pic?.original?.split("//")[1]}`,
            };
          }),
          address: property?.address,
          logo: JSON.parse(localStorage.getItem("agent")).userImage,
          travelAgency: JSON.parse(localStorage.getItem("travelAgency")),
        };

        const pdf = await axios.post(`${baseURL}/pdf/generate`, payload, {
          responseType: "blob",
        });
        const blob = new Blob([pdf.data], { type: "application/pdf" });

        // Create a download link for the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${payload?.title}`;
        document.body.appendChild(link);
        link.click();

        // Clean up the object URL after the download is complete
        URL.revokeObjectURL(url);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const setNextPic = () => {
    setPicIndex(picIndex + 1);
  };

  const setPrevPic = () => {
    let p = picIndex - 1;
    if (p < 0) {
      p += property?.pictures?.length;
    }
    setPicIndex(p);
  };

  const doSearch = (params) => {
    history.push(PATH_SEARCH);
  };

  const doHold = (params) => {
    //console.log("go reserve!");
    history.push(PATH_RESERVE, { property });
  };

  if (property == null) {
    history.push(PATH_SEARCH);
  }

  if (property) {
    //console.log("propertyData:", property);
    let searchPropertiesArray = [];
    let prop = UseCreateObject(property);
    //console.log("prop >>>: ", prop);
  }
  let pic = null;
  let picPosition = 0;

  if (!isNullOrEmptyArray(property?.pictures) && picIndex != null) {
    pic = property?.pictures[picIndex % property?.pictures?.length].original;
    picPosition = picIndex % property?.pictures?.length;
    console.log(property?.pictures?.length, picIndex, pic);
  }

  const bullet = (index) => {
    return (
      <span
        key={index}
        style={{
          fontSize: "26px",
          color: index === picPosition ? "#44C8F5" : "#D1D1D1",
          padding: "0 3px",
        }}
      >
        &bull;
      </span>
    );
  };

  const renderAmenities = (text, i) => {
    return (
      <div key={i} className="property-page-body-top-left-info-amenity">
        {text}
      </div>
    );
  };

  const renderAmount = (title, pic, amount) => {
    return (
      <div className="property-page-body-top-left-info-amount">
        <img src={pic} alt="" style={{ width: "40px" }} />
        <span>{title}</span>
        {amount ? (
          <span style={{ fontSize: "20px" }}>{amount}</span>
        ) : (
          <span style={{ fontSize: "20px" }}>&nbsp;</span>
        )}
      </div>
    );
  };

  const amenities = property?.amenities;
  const summary = property?.publicDescription?.summary;
  prop = UseCreateObject(property);
  const mapContainerStyle = {
    height: "400px",
    width: "800px",
  };

  const center = {
    lat: prop.lat,
    lng: prop.lng,
  };

  const position = {
    lat: prop.lat,
    lng: prop.lng,
  };

  const onLoad = (marker) => {
    //console.log("position: ", position);
    //console.log("marker: ", marker);
  };

  return (
    <div className="property-page-wrapper">
      <PageHeader bgColor="#133B71" doSearch={doSearch} />

      <div ref={ref} className="property-page-container">
        <div className="property-main-top">

          <div className="property-main-picture-container">
            <div className="img-slider">
              <ImageWithHover
                path={picLeft}
                pathOver={picLeftOn}
                className="property-page-prev-next-pic"
                style={{ left: "10px" }}
                onClick={setPrevPic}
              />
              <div
                className="property-main-picture"
                style={{
                  backgroundImage: pic ? `url(${pic})` : "transparent",
                  backgroundSize: "cover",
                }}
              />
              <ImageWithHover
                path={picRight}
                pathOver={picRightOn}
                className="property-page-prev-next-pic"
                style={{ right: "10px" }}
                onClick={setNextPic}
              />
            </div>
          </div>
          <div className="property-main-picture-bullets">
            {property &&
              property?.pictures &&
              !isNullOrEmptyArray(property?.pictures) &&
              property?.pictures.map((pic, i) => bullet(i))}
          </div>
          <div className="d-flex justify-content-between title-section">
            <div className="w-75">
              <div className="property-page-body-top-title text-start">
                <LinesEllipsis
                  text={property?.title}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                />
              </div>
              <div className="property-page-body-top-subtitle text-start">
                {prop.fullAddress}
              </div>
            </div>
            <div className="d-flex h-100 ">
              {/* <img
                className="property-main-picture-icon"
                src={iconShareLink}
                alt=""
              /> */}
              {/*
              <ReactToPdf
                targetRef={ref}
                scale={0.65}
                filename="villa-tracker.pdf"
              >
                {({ toPdf }) => (
                  <img
                    onClick={toPdf}
                    className="property-main-picture-icon"
                    src={iconSharePdf}
                    alt=""
                  />
                )}
              </ReactToPdf>
                */}
              <img
                className="property-main-picture-icon"
                src={iconSharePdf}
                alt=""
                disabled={loading}
                onClick={handleSend}
              />
              <img
                className="property-main-picture-icon"
                src={iconLike}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="property-page-body-top ">
          <div className="property-page-body-top-right">
            <Row style={{ alignItems: "flex-start" }}>
              <div className="property-page-body-top-price fw-bold">
                {prop.basePrice}
              </div>
              <div className="property-page-body-top-night fw-semibold">
                Per Night
              </div>
            </Row>

            <Row style={{ padding: "10px 0" }}>
              <div className="property-page-body-top-commission-price">
                $1,302
              </div>
              <div className="property-page-body-top-commission-title">
                &nbsp;&nbsp;Agency Commission
              </div>
            </Row>

            <div className="property-page-body-top-dates">
              <div style={{ width: "180px", textAlign: "left" }}>
                <div className="property-page-body-top-date-title">Arrive</div>
                <div className="datePicker">
                  <DatePickerArrival date={getStorageValue("dateFrom")} />
                </div>
              </div>

              <div style={{ width: "180px", textAlign: "left" }}>
                <div className="property-page-body-top-date-title">
                  Departure
                </div>
                <div className="datePicker">
                  <DatePickerDeparture date={getStorageValue("dateTo")} />
                </div>
              </div>
            </div>

            <br />
            <br />

            <Button
              onClick={doHold}
              style={{ width: "100%" }}
              variant="green"
              text="Request a Hold"
            />
          </div>
          <div className="property-page-body-top-left">
            <div className="property-page-body-top-left-info">
              <hr style={{ color: "#E7E7E7" }} />
              <div className="property-page-body-feature-row">
                <Row>
                  {renderAmount("Guests", bath, prop.accommodates)}
                  {renderAmount("Bedrooms", bath, prop.bedrooms)}
                  {renderAmount("Bathrooms", bath, prop.bathrooms)}
                  {/* {renderAmount("Bathrooms", bath, prop.bathrooms)} */}

                  {prop.tags.indexOf("eventCollection") > -1
                    ? renderAmount("Event Places", eventsIcon)
                    : ""}
                  {prop.tags.indexOf("familyCollection") > -1
                    ? renderAmount("For Families", familyIcon)
                    : ""}
                  {prop.tags.indexOf("petsCollection") > -1
                    ? renderAmount("Pets Welcome", petsIcon)
                    : ""}
                  {prop.tags.indexOf("sustainCollection") > -1
                    ? renderAmount("Sustainable", sustainIcon)
                    : ""}
                </Row>
              </div>

              <hr style={{ color: "#E7E7E7" }} />
              <div className="overview-section py-5 col-8 col-md-12">
                <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                  Overview
                </div>
                <ul>
                  <li style={{ paddingTop: "10px" }}>{summary}</li>
                </ul>
              </div>

              <div className="border-top py-5 col-8 col-md-12">
                <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                  Amenities
                </div>

                <div className="amenities-section">
                  {amenities.map((a, i) => renderAmenities(a, i))}
                </div>
              </div>
              <div className="" />
              <div className="w-75 border-top">
                <div className="property-checkIn-checkOut py-5">
                  <h1 className="py-4">Check-in and Check-out</h1>
                  <div className="checkIn">
                    <p>26.1.2023</p>
                    <p className="reservation-time">03:00 PM (24-hours)</p>
                  </div>
                  <div className="checkOut">
                    <p className="reservation-date">27.1.2023</p>
                    <p className="reservation-time">03:00 PM (24-hours)</p>
                  </div>
                </div>
              </div>
              <div className="property-rules">
                <div
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    paddingBottom: "5px",
                  }}
                >
                  House rules and a cancellation policy
                </div>
                <div style={{ fontSize: "20px", color: "#707070" }}>
                  {prop.houseRules}
                  <br />
                  we'll make every effort to work with property management to
                  find options if your
                  <br />
                  plans change but refunds cannot be guaranteed and and on a
                  best-effort basis.
                  <br />
                  <br />
                  <div className="link18-bold">
                    Click here to view complete property terms & conditions
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E7E7E7" }} />
            </div>
          </div>
        </div>
        <div className="property-location">
          <div style={{ padding: "0 40px" }}>
            <h1>Location</h1>
            <div>
              <LoadScript googleMapsApiKey="AIzaSyA6TmWVrRTP93bEIGqQG9e_1qvVwcwNJ2k">
                <GoogleMap
                  id="marker-example"
                  mapContainerStyle={{ width: "100%", height: "500px" }}
                  zoom={12}
                  center={center}
                >
                  <Marker onLoad={onLoad} position={position} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Property;
