const UseCreateObject = (props) => {
    // //console.log("props", props);
    return {
        count: props.count,
        id: props._id,
        nickName: props.nickname || "",
        name: props.title || "",
        // name: props.roomType || "",
        roomType: props.roomType || "",
        bedrooms: props.bedrooms || 0,
        bedType: props.bedType || "",
        bathrooms: props.bathrooms || 0,
        beds: props.beds || 0,
        // guesty_link: props.integrations[0]?.externalUrl,
        filename: props.picture.thumbnail || props.filename || props.original || props.large || "",
        photos: props.pictures,
        countryCode: props.address.countryCode,
        countryName: props.address.country || "",
        city: props.address.city || "",
        state: props.address.state || "",
        street: props.address.street || "",
        fullAddress: props.address.full || "",
        lat: props.address.lat,
        lng: props.address.lng,
        currency: props.prices.currency || "",
        basePrice: props.prices.basePrice + " " + props.prices.currency || "",
        basePriceUSD: props.prices.basePriceUSD ? props.prices.basePriceUSD + "$" : "" || "",
        weekendPrice: props.prices.basePrice + " " + props.prices.currency || "",

        publicDescription: "Belowe are the public description",
        houseRules: props.publicDescription?.houseRules || "",
        interactionWithGuests: props.publicDescription?.interactionWithGuests || "",
        neighborhood: props.publicDescription?.neighborhood || "",
        notes: props.publicDescription?.notes || "",
        space: props.publicDescription?.space || "",
        summary: props.publicDescription?.summary || "",
        transit: props.publicDescription?.transit || "",
        searchable: props.address.searchable || "",
        propertyCurrency: props.prices.currency || "$USD",
        propertyType: props.propertyType || "",
        type: props.type || "",
        activate: props.active === true ? 1 : 0 || "",
        created_at: props.createdAt || "",
        lastActivityAt: props.lastActivityAt || "",
        lastSyncedAt: props.lastSyncedAt || "",
        lastUpdatedAt: props.lastUpdatedAt || "",
        cleaningFee: props.prices.cleaningFee || "",
        extraPersonFee: props.prices.extraPersonFee || "",
        guestsIncludedInRegularFee: props.prices.guestsIncludedInRegularFee || "",
        monthlyPriceFactor: props.prices.monthlyPriceFactor || "",
        weeklyPriceFactor: props.prices.weeklyPriceFactor || "",
        allowsChildren:
            props.publicDescription?.guestControls?.allowsChildren || "",
        allowsEvents: props.publicDescription?.guestControls?.allowsEvents || "",
        allowsInfants: props.publicDescription?.guestControls?.allowsInfants || "",
        allowsPets: props.publicDescription?.guestControls?.allowsPets || "",
        allowsSmoking: props.publicDescription?.guestControls?.allowsSmoking || "",
        amenities:
            props.amenities.length > 1
                ? props.amenities.map((item) => item).join(",")
                : "",
        tags: props?.tags?.length > 1
            ? props?.tags?.map((item) => item).join(",")
            : (props.tags?.length == 1) ? props.tags[0] : "",
        amenitiesNotIncluded:
            props.amenitiesNotIncluded.length > 1
                ? props.amenitiesNotIncluded.map((item) => item).join(",")
                : "",
        rooms: props.listingRooms,
        accommodates: props.accommodates || "",
        // is_deleted: "0",
        // pmId: "8793283",
        // pmName: "Smiling House",
        // region_id: "31",
        // is_quote_on_request: "0",
        // agent_name: "Evelyn",
        // ical_adress:"https://www.airbnb.com/calendar/ical/26887873.ics?s=08b021c86d819ddf21ff33e413346841",
        // bookingpal_id: "1235431820",
        // is_approve_on_request: "0",
        // updated_at: "2022-12-19 09:14:31",
        // too_cheap: "0",
        // too_small: "0",
        // not_by_standards: "0",
        // other_decline_status: "",
        // propertyTypeCategory: "PCT3",
        // symbol_native: "₪",
        // availability: "0",
        // nightlyPrice: "922.00",
        // contact: null,
        // inquiry_email: null,
        // wa: null,
        // phone: null,
        // notes: null,
        // countryCode: "IL",
    };
};

export default UseCreateObject;
