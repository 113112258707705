export const PATH_Welcome = '/welcome';
export const PATH_SIGNUP = '/signup';

export const PATH_HOME = '/home';
export const PATH_LOGIN = '/login';
export const PATH_FORGOT_PASSWORD = '/forgotPassword';
export const PATH_VERIFY_CODE = '/verifycode/:id';
export const PATH_SIGNUP_THANKS = '/signupthanks';
export const PATH_SEARCH = '/propertySearch';
export const PATH_SHUB = '/shub';
export const PATH_MAP = '/map';
export const PATH_CLIENTS = '/clients';
export const PATH_LISTINGS = '/listings';
export const PATH_RESERVATIONS = '/reservations';
export const PATH_FAVORITES = '/favorites';
export const PATH_REPORTS = '/reports';
export const PATH_PROPERTY = '/property';
export const PATH_PROPERTY_ID = '/property/:id?';
export const PATH_PROPERTY_EDIT = '/propertyEdit';
export const PATH_PROPERTY_X = '/property/x';
export const PATH_RESERVE = '/propertyReservePage';
export const PATH_PROFILE = '/profile';
export const PATH_HOT_DESTINATIONS = '/hotdestinations';
export const PATH_WISH_LIST = '/wishlist';
export const PATH_COLLECTIONS = '/collections';
export const PATH_INTOUCH = '/intouch';
export const PATH_FAQ = '/faq';
export const PATH_ADMIN = '/admin';
export const PATH_PARTNERS = '/partners';

const constants = {
	ENV: 'LOCAL',
	BASE_URL: 'https://backend.villatracker.com',
	BASE_URL3: 'http://localhost:8080',
	BASE_URL2: 'https://vt-backend-8zwvrrlxf-smiling-house.vercel.app',
	BASE_URL1: 'http://vtbackend-env.eba-b3vxhzrg.us-east-2.elasticbeanstalk.com',
	GLOBAL_ACTION_INIT_STATE: 'GLOBAL_ACTION_INIT_STATE',
	PAGING_PAGE_SIZE: 21,
	PAGING_CLIENT_SIZE: 20,
	PAGING_LISTING_SIZE: 5,
	PAGING_AGENCIES_SIZE: 20,
	REACT_APP_STORAGE_SIGNED_USER_KEY: "selfit-frontend-user-data",

	TOAST_TYPE_INFO: 'toast_info',
	TOAST_TYPE_SUCCESS: 'toast_success',
	TOAST_TYPE_WARN: 'toast_warn',
	TOAST_TYPE_ERROR: 'toast_error',
};

export default constants;
