import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, } from "@mui/material";
import Icon from 'react-web-vector-icons';
import VTChannelIcon from "../../assets/channels/icons/VTChannel.svg";
import VTChannelIconOn from "../../assets/channels/icons/VTChannel-on.svg";
import VTChannelIconOnBlue from "../../assets/channels/icons/VTChannel-on-blue.svg";
import VTChannelLabel from "../../assets/channels/icons/label-VTChannel.svg";

import SHChannelIcon from "../../assets/channels/icons/SHChannel.svg";
import SHChannelIconOn from "../../assets/channels/icons/SHChannel-on.svg";
import SHChannelIconOnBlue from "../../assets/channels/icons/SHChannel-on-blue.svg";
import SHChannelLabel from "../../assets/channels/icons/label-SHChannel.svg";

import TLChannelIcon from "../../assets/channels/icons/TLChannel.svg";
import TLChannelIconOn from "../../assets/channels/icons/TLChannel-on.svg";
import TLChannelIconOnBlue from "../../assets/channels/icons/TLChannel-on-blue.svg";
import TLChannelLabel from "../../assets/channels/icons/label-TLChannel.svg";

import eventsIcon from "../../assets/special-collection/icons/events.svg";
import eventsIconOn from "../../assets/special-collection/icons/events-on.svg";
import eventsIconOnBlue from "../../assets/special-collection/icons/events-on-blue.svg";
import eventsLabel from "../../assets/special-collection/icons/label-events.svg";

import dogsIcon from "../../assets/special-collection/icons/dogs.svg";
import dogsIconOn from "../../assets/special-collection/icons/dogs-on.svg";
import dogsIconOnBlue from "../../assets/special-collection/icons/dogs-on-blue.svg";
import dogsLabel from "../../assets/special-collection/icons/label-pets.svg";

import greenIcon from "../../assets/special-collection/icons/green.svg";
import greenIconOn from "../../assets/special-collection/icons/green-on.svg";
import greenIconOnBlue from "../../assets/special-collection/icons/green-on-blue.svg";
import greenLabel from "../../assets/special-collection/icons/label-sustainable.svg";

import familiesIcon from "../../assets/special-collection/icons/kids.svg";
import familiesIconOn from "../../assets/special-collection/icons/kids-on.svg";
import familiesIconOnBlue from "../../assets/special-collection/icons/kids-on-blue.svg";
import familiesLabel from "../../assets/special-collection/icons/label-families.svg";

import filtersIcon from "../../assets/special-collection/icons/filter.png";

import Button from "../../components/Buttons/Button/Button";
import pageBg from '../../assets/bk_pool.png';
import { data } from "./makeData.js";
import axios from "axios";
import { baseURL } from "../../core/index.js";
import PageHeader from "../../components/PageHeader";
import { PATH_PROPERTY_EDIT } from "../../Util/constants";
import { useHistory } from "react-router-dom";

import "./Listings.scss";
import Paging from "../../components/Paging";
import constants from "../../Util/constants";
import closeIcon from '../../assets/icons/closeIcon.png';
import { BsChevronDown } from "react-icons/bs";
import { IoIosSearch } from "react-icons/io";
import { BiCalendarCheck } from "react-icons/bi";
import AuthService from "../../services/auth.service";
import swal from "sweetalert";
import CollectionIcon from "../../components/CollectionIcon";

const NEW_CLIENT = {
    id: "-1",
    firstName: "",
    lastName: "",
    email: "",
    state: "",
    phone: "",
};

const Listings = ({ token }) => {
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [selectedChannels, setSelectedChannels] = useState([]);

    const [searchInputes, setsearchInputes] = useState({
        customerName: "",
        customerEmail: "",
    })
    const history = useHistory();
    const accountId2 = localStorage.getItem("accountId");
    const partner = JSON.parse(localStorage.getItem("partner"));
    const agent = JSON.parse(localStorage.getItem("agent"));
    const agency = JSON.parse(localStorage.getItem("travelAgency"));
    const user_image = agency?.userImage;
    var xdata = [];

    const [events, setEvents] = useState();
    const [emailLog, setemailLog] = useState("");
    const [NickNameLog, setNickNameLog] = useState("");
    const [phoneLog, setePhoneLog] = useState("");
    const [singleClientData, setsingleClientData] = useState([])
    const [selectedClientToShowOffers, setSelectedClientToShowOffers] = useState(null);
    const [selectedClientToShowSavedSearch, setSelectedClientToShowSavedSearch] = useState(null);
    const [selectedClientToEdit, setSelectedClientToEdit] = useState(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(() => data);
    // state for Listings
    const [listings, setListings] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    //modal state
    const [modalData, setModalData] = useState({
        title: "Add new Client",
    });
    // console.log("Listings >>>>", Listings);
    const [filterListings, setFilterListings] = useState();
    // console.log("filterListings >>>>", filterListings);
    const [searchListings, setSearchListings] = useState("");
    const [pageNumber, setPageNumber] = useState(0);

    const toggleCollection = (row, collection, xdata) => {
        var xdataPayload = xdata; // saving the xdata 
        console.log("before:", xdataPayload)
        console.log("pressed=", collection + row._id, " xdata state=",
            (xdataPayload.tags?.findIndex((i) => i === collection) > -1));
        if ((!row.tags?.findIndex((i) => i === collection) > -1)) {
            if (selectedCollections.findIndex((i) => i === collection + row._id) > -1) {
                //remove collection from xdata.tags
                xdataPayload.tags=xdataPayload.tags?.filter((f) => f !== collection)
                setSelectedCollections(
                    selectedCollections.filter((f) => f !== collection + row._id)
                )
            } else { //add to channel or collection to xdata tags
                setSelectedCollections([...selectedCollections, collection + row._id])
                xdataPayload.tags = [...xdataPayload.tags, collection]
                console.log("insert collection to xdata tags!", collection)
            }
            console.log("after:", xdataPayload)
            updateXdata(row._id,xdataPayload)
        }
    }

    const toggleChannel = (row, channel, xdata) => {
        var xdataPayload = xdata; // saving the xdata 
        console.log("before:", xdataPayload)
        console.log("pressed=", channel + row._id, " xdata state=",
            (xdataPayload.channel?.findIndex((i) => i === channel) > -1));
        if ((!xdataPayload.channel?.findIndex((i) => i === channel) > -1)) {
            if (selectedChannels.findIndex((i) => i === channel + row._id) > -1) {
                //remove channel from xdata.channel and selectedChannels
                xdata.channel=xdataPayload.channel.filter((f) => f !== channel)
                setSelectedChannels(
                    selectedChannels.filter((f) => f !== channel + row._id)
                )
            } else { //add to channel or channel to xdata tags
                setSelectedChannels([...selectedChannels, channel + row._id])
                xdataPayload.channel = [...xdataPayload.channel, channel]
            }
            console.log("after:", xdataPayload)
            updateXdata(row._id,xdataPayload)
        }
    }

    const filterCollections = () => {
        // console.log("tags:", selectedCollections);
        const tagsString = Object.keys(selectedCollections)
            .map((key) => selectedCollections[key])
            .join(",");
        //localStorage.setItem("tags", tagsString);
        //doSearch(0, { params: "a" });
    };

    const doSearch = pageNumber => {
        console.log("skipping : ", pageNumber * constants.PAGING_CLIENT_SIZE);
        getAllListings();
        //setShowSelection(false);
        //dispatch(propertyActions.loadProperties(pageNumber));
    };

    let clientPagingFrom = 1 + pageNumber * constants.PAGING_CLIENT_SIZE;
    let clientPagingTo = (pageNumber + 1) * constants.PAGING_CLIENT_SIZE;

    const onChangePage = pageNumber => {
        console.log("page=", pageNumber);
        setPageNumber(pageNumber);
        clientPagingFrom = 1 + pageNumber * constants.PAGING_CLIENT_SIZE;
        clientPagingTo = (pageNumber + 1) * constants.PAGING_CLIENT_SIZE;
        doSearch(pageNumber);
    };

    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const token2 = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X29iamVjdF9pZCI6Mzk5MTU4NzUsInVzZXJfaWQiOiI0MDY2NTAyMSIsInVzZXJfbmFtZSI6InN5c3RlbStsdW5hLTh5NXljIiwic2NvcGUiOlsiYnJpdm8uYXBpIl0sImF0aSI6ImI5MTliYmJiLTA1ZWItNDlmOC05MjlhLWM0MTJlYzY3NWI2YyIsImlzc3VlZCI6IjE2NzUzNzA2NDMzNzMiLCJleHAiOjIyOTczMzM3MjcsInNlcnZpY2VfdG9rZW4iOm51bGwsImF1dGhvcml0aWVzIjpbIlJPTEVfU1VQRVJfQURNSU4iLCJST0xFX0FETUlOIl0sImp0aSI6IjExODQzYjg2LWIyYzUtNGMwNS1hYWZlLTcxZTI4NGIyNjNlOCIsImNsaWVudF9pZCI6IjkzOTFlYjVkLWUwNmUtNDY4MS1iNTdhLWQwZTU3NDhhM2RlZSIsIndoaXRlX2xpc3RlZCI6ZmFsc2V9.Mqmx7onIVz_EVAunhwqBAhAmlsGXMQ18hh_EV_61KQIpaGXlrgXgx1hOOdNWLFriG3Un6jfS7H7vwMAYmBT6-8yl9L7VB7Cpxva49XozuSJazQ42UDDlTOsnWAmatzmFna-Uzjc8MDfVQbR8AwMiFq_Jb9ViaJ4XBkj2KhEKs1g';

    const userRequest = axios.create({
        headers: {
            Authorization: `Bearer ${token2}`
        }
    });

    const getAllListings = async () => {
        const accountId = partner.accountID;//585a39dbe43900100017e9e8
        const ShubResponse = await userRequest.get(`https://api.triangle.luxury/local/listings?accountId=${accountId}`, {
            params: { limit: constants.PAGING_LISTING_SIZE, skip: clientPagingFrom - 1 },
        }
        );
        console.log("fetched properties per account >>>>", ShubResponse.data.count);
        setListings(ShubResponse.data.listings);
        setFilterListings(ShubResponse.data.listings);
        localStorage.setItem("count", ShubResponse.data.count);
    };
    
    const updateXdata = async (ID,xdataPayload) => {
        //585a39dbe43900100017e9e8
        const ShubResponse = await userRequest.post(`https://api.triangle.luxury/local/xdata/${ID}`, 
            xdataPayload );
        console.log("saved xdata for ID>>>>", ID,":",xdataPayload);
        getAllListings(); //re-read page listings
    };

    useEffect(() => {
        getAllListings();

    }, [isRefresh]);

    const handleSearchListings = (name, value) => {
        setsearchInputes({ ...searchInputes, [name]: value })
    }
    const handleSearchButton = () => {
        AuthService.GetClientCstApi(searchInputes.customerName, searchInputes.customerEmail).then((response) => {
            if (response) {
                setListings(response.listings)
                if (response.listings.length == 0) {
                    swal({
                        show: true,
                        icon: 'error',
                        title: 'Opps!!',
                        text: "No Data Found"
                    })
                }
            }
        }).catch((e) => {
            console.log(e)
        })

    }
    const headerSearchRow = () => {
        return (
            <div className="listings-search-container row">
                <div className="col-sm-2">
                    <input type="text" className="listings-search-input form-control" placeholder="Client name / Nick name" onChange={(e) => handleSearchListings("customerName", e.target.value)} />
                </div>
                <div className="col-sm-2">
                    <input type="text" className="listings-search-input form-control" placeholder="Enter customer email" onChange={(e) => handleSearchListings("customerEmail", e.target.value)} />
                </div>
                <div className="col-sm-1">
                    <Button style={{ height: '60px', width: '200px', fontSize: '20px' }} variant="green" text="Search" onClick={handleSearchButton} />
                </div>
                <span className="listings-search-separator" />

                <div className="col-sm-2">
                    <div className="listings-search-add-client-button" onClick={() => setSelectedClientToEdit(NEW_CLIENT)}>
                        <Icon name='pluscircle' font='AntDesign' color='white' size={30} />
                        <span style={{ paddingLeft: '15px' }} data-bs-toggle="modal" href="#exampleModalToggle">Add New Client</span>
                    </div>
                </div>
            </div>
        )
    };

    const columns = [
        {
            id: 'firstName',
            name: 'ID',
            headerStyle: { paddingLeft: '50px', backgroundColor: '#F5F5F2' },
            selector: row => row.firstName,
            cell: row => <div className="link18" onClick={() => setSelectedClientToEdit(row)}>{row.firstName}</div>,
            width: '1fr'
        }, {
            id: 'lastName',
            name: 'STATUS',
            sortable: true,
            selector: row => row.lastName,
            cell: row => <div>{row.lastName}</div>,
            width: '1fr'
        }, {
            id: 'nickName',
            name: 'CHANNEL',
            sortable: true,
            selector: row => row.nick,
            cell: row => <div>{row.nickName}</div>,
            width: '1fr'
        }, {
            id: 'email',
            name: 'Special collection',
            sortable: true,
            selector: row => row.email,
            cell: row => row.email,
            cellStyle: { width: '400px', display: 'block', padding: '10px 0px' },
            width: '1fr'
        }, {
            id: 'phone',
            name: 'Property Name',
            sortable: true,
            selector: row => row.phone,
            cell: row => <div>{row.phone}</div>,
            width: '1fr'
        }, {
            id: 'offers',
            name: 'Location',
            width: '1fr',
            header: (column, index) => (
                <div key={index} style={{ color: '#1B9C5D', backgroundColor: '#F5F5F2', fontSize: '22px', fontWeight: 500 }}>
                    Location
                </div>
            ),
            headerStyle: { backgroundColor: '#F5F5F2' },
            sortable: true,
            selector: row => row.offers,
            cell: (row) => (
                <div onClick={() => setSelectedClientToShowOffers(row)} style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', backgroundColor: '#F9F9F8', justifyContent: 'center' }}>
                    <Icon
                        name='calendar-check'
                        font='MaterialCommunityIcons'
                        color='#1B9C5D'
                        size={26}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ),
            width: '150px'
        }, {
            id: 'savedSearch',
            name: 'approving Agent',
            header: (column, index) => (
                <div key={index} style={{ color: '#1B9C5D', backgroundColor: '#F5F5F2', fontSize: '21px', lineHeight: '21px', fontWeight: 500 }}>
                    Agent
                </div>
            ),
            headerStyle: { backgroundColor: '#F5F5F2' },
            sortable: true,
            selector: row => row.savedSearch,
            cell: (row) => (
                <div onClick={() => setSelectedClientToShowSavedSearch(row)} style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', backgroundColor: '#F9F9F8', justifyContent: 'center' }}>
                    <Icon
                        name='search'
                        font='MaterialIcons'
                        color='#1B9C5D'
                        size={26}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ),
            width: '150px'
        }
    ];

    let totalListings = localStorage.getItem("count") ? localStorage.getItem("count") : 0;
    const ListingsPagingFrom = 1 + pageNumber * constants.PAGING_CLIENT_SIZE;
    let ListingsPagingTo = (pageNumber + 1) * constants.PAGING_CLIENT_SIZE;
    if (totalListings < ListingsPagingTo) {
        ListingsPagingTo = totalListings;
    }
    const handleEditmodel = (agentId, agencyId, clientId) => {
        setSelectedClientToEdit(agentId, agencyId, clientId)

        AuthService.GetSingleClientApi(agentId, agencyId, clientId).then((response) => {
            if (response) {
                setsingleClientData(response.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    const editProperty = (property, propertyId) => {
        console.log(property, propertyId);
        history.push(PATH_PROPERTY_EDIT, { property, propertyId });
    };
    const handeClaenderButton = (agent_id, email, client_id, nickName, phone) => {
        setSelectedClientToShowOffers(agent_id, email, nickName, phone)
        setemailLog(email)
        setNickNameLog(nickName)
        setePhoneLog(phone)
    }

    const renderSpecialCollections = (row, xdata) => {
        return (
            /* 	<div>
                    <img src={specialEvents} className="img-fluid" style={{ marginRight: '5px', width: '25px' }} />
                    <img src={specialFamilies} className="img-fluid" style={{ marginRight: '5px', width: '25px' }} />
                    <img src={greenIcon} className="img-fluid" style={{ marginRight: '5px', width: '25px' }} />
                    <img src={dogsIcon} className="img-fluid" style={{ marginRight: '5px', width: '25px' }} />
                </div> */
            <div className="search-main-selection-icons"
                style={{
                    paddingTop: '15px',
                    display: 'grid',
                    gridTemplateColumns: '80px 80px 80px 80px',
                    justifyItems: 'center',
                }}>
                <CollectionIcon
                    path={eventsIcon}
                    pathOver={eventsIconOn}
                    pathOver2={eventsIconOnBlue}
                    selected={row.tags?.indexOf("eventCollection") > -1}
                    selected2={xdata.tags?.indexOf("eventCollection") > -1 && selectedCollections?.indexOf("eventCollection" + row._id) > -1}
                    label={eventsLabel}
                    onClick={() => toggleCollection(row, "eventCollection", xdata)}
                />
                <CollectionIcon
                    path={familiesIcon}
                    pathOver={familiesIconOn}
                    pathOver2={familiesIconOnBlue}
                    selected={
                        row.tags?.indexOf("familyCollection") > -1
                    }
                    selected2={xdata.tags?.indexOf("familyCollection") > -1 && selectedCollections?.indexOf("familyCollection" + row._id) > -1}
                    label={familiesLabel}
                    onClick={() => toggleCollection(row, "familyCollection", xdata)}
                />
                <CollectionIcon
                    path={dogsIcon}
                    pathOver={dogsIconOn}
                    pathOver2={dogsIconOnBlue}
                    selected={row.tags?.indexOf("petsCollection") > -1}
                    selected2={xdata.tags?.indexOf("petsCollection") > -1 && selectedCollections.indexOf("petsCollection" + row._id) > -1}

                    label={dogsLabel}
                    onClick={() => toggleCollection(row, "petsCollection", xdata)}
                />
                <CollectionIcon
                    path={greenIcon}
                    pathOver={greenIconOn}
                    pathOver2={greenIconOnBlue}
                    selected={
                        row.tags?.indexOf("sustainCollection") > -1
                    }
                    selected2={xdata.tags?.indexOf("sustainCollection") > -1 && selectedCollections.indexOf("sustainCollection" + row._id) > -1}

                    label={greenLabel}
                    onClick={() => toggleCollection(row, "sustainCollection", xdata)}
                />

            </div>
        )
    };
    const renderChannels = (row, xdata) => {
        return (

            <div className="channels-main-selection-icons"
                style={{
                    paddingTop: '15px',
                    display: 'grid',
                    gridTemplateColumns: '80px 80px 80px',
                    justifyItems: 'center',
                }}>
                <CollectionIcon
                    path={VTChannelIcon}
                    pathOver={VTChannelIconOn}
                    pathOver2={VTChannelIconOnBlue}
                    selected={xdata.channel.findIndex((i) => i === "VT") > -1}
                    selected2={selectedChannels?.indexOf("VT" + row._id) > -1}
                    label={VTChannelLabel}
                    onClick={() => toggleChannel(row, "VT", xdata)}
                />
                <CollectionIcon
                    path={SHChannelIcon}
                    pathOver={SHChannelIconOn}
                    pathOver2={SHChannelIconOnBlue}
                    selected={xdata.channel.findIndex((i) => i === "SH") > -1}
                    selected2={selectedChannels?.indexOf("SH" + row._id) > -1}
                    label={SHChannelLabel}
                    onClick={() => toggleChannel(row, "SH", xdata)}
                />
                <CollectionIcon
                    path={TLChannelIcon}
                    pathOver={TLChannelIconOn}
                    pathOver2={TLChannelIconOnBlue}
                    selected={xdata.channel.findIndex((i) => i === "TL") > -1}
                    selected2={selectedChannels?.indexOf("TL" + row._id) > -1}
                    label={TLChannelLabel}
                    onClick={() => toggleChannel(row, "TL", xdata)}
                />
            </div>
        )
    };
    return (
        <div className="listings-container"
        //  style={{ backgroundImage: `url(${pageBg})` }}
        >
            <div>
                <PageHeader searchOpen={null} topBgColor="#133B71"></PageHeader>
                {headerSearchRow()}
            </div>

            <div className="listings-main">
                <div className="listings-title">{partner.partnerName} / ID {partner.accountID} / Listings</div>
                <div className="listings-paging">Displaying  {ListingsPagingFrom}-{ListingsPagingTo} of {totalListings ? totalListings : "?"} </div>

                {<Paging perPage={constants.PAGING_LISTING_SIZE} totalItems={totalListings} currentPage={pageNumber} onChangePage={onChangePage} />}
                <div style={{ padding: '0 20px' }}>
                    <div class="table-responsive">
                        <div className="table-responsive">
                            <table class="table">
                                <thead style={{ backgroundColor: "#f9f9f7" }} >
                                    <tr>
                                        {columns?.map((iteam, index) => {
                                            return <>
                                                <th key={index} scope="col" className="p-4 " style={{ cursor: "pointer" }}><h3>{iteam.name} <BsChevronDown /></h3></th>
                                            </>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {listings.map((iteam, index) => {
                                        return <>
                                            <tr >
                                                <th className="px-4 p-3 text-primary text-decoration-underline cst-cursor"><h4 onClick={() => editProperty(iteam, iteam.listing._id)}>{iteam.listing._id != null ? iteam.listing._id : "-"}</h4></th>
                                                <td className="px-4 p-3"><h4>{iteam.xdata?.status}</h4></td>
                                                <td className="px-4 p-3 "><h4>{renderChannels(iteam.listing, iteam.xdata)}</h4></td>
                                                <td className="px-4 p-3"><h4>{renderSpecialCollections(iteam.listing, iteam.xdata)}</h4></td>
                                                <td className="px-4 p-3"><h4>{iteam.listing.title}</h4></td>
                                                <td className="px-4 p-3"><h4>{iteam.listing.address.city + ',' + iteam.listing.address.country}</h4></td>
                                                <td className="px-4 p-3"><h4>{iteam.agent || agent.firstName + '' + agent.lastName}</h4></td>
                                            </tr>
                                        </>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                modalData={modalData}
            />
        </div>
    );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    modalData,
}) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ""] = "";
            return acc;
        }, {})
    );

    //console.log("data from modal >>>>", modalData);

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle
                textAlign="center"
                className="font-color"
                style={{ background: "#F2F9FC" }}
            >
                {modalData.title}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: "100%",
                            minWidth: { xs: "300px", sm: "360px", md: "500px" },
                            gap: "1.5rem",
                        }}
                    >
                        <div className="row g-3 pt-3">
                            <div className="col-md-6 px-4">
                                <div className="row mb-2">
                                    <label htmlFor="inputText4" className="form-label mb-1 ps-0">
                                        Listing Name*
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control rounded-0 py-2"
                                        id="inputText4"
                                        placeholder="Enter name"
                                    />
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="inputText14" className="form-label mb-1 ps-0">
                                        Property Nick*
                                    </label>
                                    <input
                                        type="name"
                                        className="form-control rounded-0 py-2"
                                        id="inputText14"
                                        placeholder="CAE000"
                                    />
                                </div>
                                <div className="row mb-2">
                                    <label
                                        htmlFor="inputAddress"
                                        className="form-label mb-1 ps-0"
                                    >
                                        Client Phone*
                                    </label>
                                    <input
                                        type="phone"
                                        className="form-control rounded-0 py-2"
                                        id="inputAddress"
                                        placeholder="+41-79-489-7021"
                                        maxLength={11}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 px-4">
                                <div className="row mb-2">
                                    <label
                                        htmlFor="inputAddress"
                                        className="form-label mb-1 ps-0"
                                    >
                                        Nick Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control rounded-0 py-2"
                                        id="inputAddress"
                                        placeholder="Smiling"
                                    />
                                </div>
                                <div className="row mb-2">
                                    <label
                                        for="exampleFormControlTextarea1"
                                        class="form-label mb-1 ps-0"
                                    >
                                        Notes
                                    </label>
                                    <textarea
                                        class="form-control rounded-0 py-2"
                                        id="exampleFormControlTextarea1"
                                        rows="4"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
                <Button onClick={onClose}>Cancel</Button>
                <button
                    type="submit"
                    className="btn btn-success border-radius-0 w-25 py-2"
                    style={{ backgroundColor: "#165093" }}
                    onClick={handleSubmit}
                >
                    Save
                </button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default Listings;
