import React, {useEffect} from "react";
import './LoadingBox.scss';

const LoadingBox = props => {
	const {visible} = props;

	if(visible) {
		return (<div className="loading-box" />)
	}

	return null;
};

export default LoadingBox;