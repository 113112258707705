import React from 'react';
import "./App.scss";
import MainPage from "./pages";

function App() {
 
  return <MainPage />;
}

export default App;
