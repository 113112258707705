import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../store/redux/User/actions";
import Auth from "./Auth/Auth";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import SignupThanks from "./SignupThanks";
import { Link, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import Profile from "./Profile/Profile";
import AgencyProfile from "./AgencyProfile/AgencyProfile";
import Listings from "./Listings2";
import Reservations from "./Reservations";
import Partners from "./Partners";
// material ui
import Welcome from "./Welcome";
import Sidebar from "../components/Sidebar";
import SearchProperty from "./SearchProperty";
import ShubPanel from "./ShubPanel";
import Map from "./Map";
import Reports from "./Reports";
import Touch from "./Touch";
import Faq from "./Faq";
import HotDestinations from "./HotDestinations";
import Collections from "./Collections";
import WishList from "./WishList";
import Favorites from "./Favorites";
import Property from "./Property";
import PropertyEdit from "./PropertyEdit";
import PropertyReservePage from "./PropertyReserve";
import {
  PATH_PARTNERS,
  PATH_LISTINGS,
  PATH_COLLECTIONS,
  PATH_FAQ,
  PATH_FAVORITES,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_HOT_DESTINATIONS,
  PATH_LOGIN,
  PATH_MAP,
  PATH_PROFILE,
  PATH_PROPERTY,
  PATH_PROPERTY_EDIT,
  PATH_PROPERTY_X,
  PATH_RESERVE,
  PATH_REPORTS,
  PATH_RESERVATIONS,
  PATH_SEARCH,
  PATH_SHUB,
  PATH_SIGNUP_THANKS,
  PATH_WISH_LIST,
  PATH_INTOUCH,
  PATH_Welcome,
  PATH_SIGNUP,
  PATH_PROPERTY_ID,
} from "../Util/constants";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../components/Icons/Logo/Logo";
import { baseURL } from "../core";
import axios from "axios";
import Header from "../components/Header/Header";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { Search } from "@mui/icons-material";
import Home from "./Home/Home";
import Signup from "./Signup/Signup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonalizeLogoModel from "../Models/PersonalizeLogoModel";
import VerifyCodePage from "./ResetPasswordPage/VerifyCodePage";
import ResetChangePasswordPage from "./ResetPasswordPage/ResetChangePasswordPage";
const logintoken = localStorage.getItem('jToken');

export const drawerWidth = 240;
const changeFontColor = {
  color: "#44C8F5",
  fontWeight: "600",
};
const fontColor = { color: "#32516D" };

function MainPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let agent = [];
  let agency = [];
  const user = useSelector((state) => state.user.user);
  const [profile, setProfile] = [];
  const [token, setToken] = useState(localStorage.getItem("jToken"));
  const [userId, setUser] = useState(localStorage.getItem("id"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [partner, setPartner] = useState(localStorage.getItem("partner"));
  useEffect(() => {

    const load = async () => {
      //const user = JSON.parse(localStorage.getItem("user"));
      const user = { "_id": "63e51f3307ae8ddb2a98a8d7", "abbreviation": "SA", "address": "Whole Street 12", "agencyName": "VillaTracker", "agency_id": 1, "agent_id": 1, "approved_at": "2023-02-22T08:50", "country": "Israel", "countryCode": "IL", "createdAt": "2023-01-29T15:09:55.000Z", "deleted": "0", "email": "2901@test.com", "favorites": "[\"5a8d54ae7b53f6002fbd6078 \",\"585a5e07c7aa3d04006993c4\",\"585a5e06c7aa3d0400698d79\"]", "firstName": "tal", "lastName": "arnon", "organization": "ISSTA", "password": "$2a$12$.nXRhAQIlYcHdmlFGZiBjeaNmls69b6GfTmjBO.cDOMbuK1iTJlnK", "phone": null, "role": "admin", "signInLast": "today at 11:00am", "status": "approved", "updatedAt": "2023-05-18T06:32:10.060Z", "userImage": "https://vt-photos.s3.us-east-2.amazonaws.com/uploads/SH LOGO BG.jpeg", "zipcode": "3792" };
      if (user) {
        dispatch(userActions.setLoggedInUser(user));

      }
    };
    load();


    /* getting property Id from URL
    */
    let urls =
      location.pathname ? location.pathname?.split("/") : '';
    console.log(urls[2]);
    setPropertyId(urls[2]);

    const propertyIndex = urls.findIndex((i) => i.value === "property");
    if (propertyIndex > -1) {
      console.log("index=", propertyIndex); // searching for property in URL
    }


    if (location.pathname !== "/signup" && location.pathname !== "/login" && location.pathname !== "/forgotPassword" && location.pathname !== "/signupthanks") {
      //  console.log(logintoken,"logintoken")
      //  console.log(location.pathname,"location.pathname")
      if (logintoken === null) {
        history.push("/welcome");
        // localStorage.setItem("destination", "israel");
        // localStorage.setItem("agent_id", 1);
        // localStorage.setItem("count", 50);
      }

      if (location.pathname == `/verifycode/${urls[2]}`) {
        history.push(`/verifycode/${urls[2]}`);
      }
      if(location.pathname == `/resetpassword/${urls[2]}`){
        history.push(`/resetpassword/${urls[2]}`);
      }
      
    }


  }, []);

  const userRequest = axios.create({
    baseURL: baseURL,
    headers: {
      token: `Bearer ${token}`,
    },
  });

  const getProfile = async () => {
    const agentId = JSON.parse(localStorage.getItem("agent_id"));
    const res = await userRequest.get(`/agent/get-profile`, {
      params: {
        agent_id: agentId
      },
    });
    //console.log("getting agent data from memory and backend response >>>", res.data.agent);
    localStorage.setItem("user", JSON.stringify(res.data.agent));
    localStorage.setItem("agent", JSON.stringify(res.data.agent));
    agent = res.data.agent;
    const agencyID = parseInt(agent.agency_id);
    //console.log("TA=", agencyID);

    const TAres = await userRequest.get(`travel-agency/get-travel-agencies`, {
      params: {
        agency_id: agencyID,
      },
    });
     agency = TAres.data.agencies[0];
    localStorage.setItem("travelAgency", JSON.stringify(agency));
  };

  useEffect(() => {
    getProfile();
  }, [userId, token]);

  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    //return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 800) {
      setActiveMenu(false);
      localStorage.setItem("screen", activeMenu);
      localStorage.setItem("screenSize", screenSize);
    } else {
      setActiveMenu(true);
      localStorage.setItem("screen", activeMenu);
      localStorage.setItem("screenSize", screenSize);
    }
  }, [screenSize]);

  const handleToggleMenu = () => {
    if (screenSize < 900) {
      setActiveMenu((preValue) => !preValue);
    } else { setActiveMenu(false) }
  };

  return (
    <>

      <Switch>
        <Route exact path={[PATH_LOGIN]}>
          <Auth stage="login" signup={true} setToken={setToken} />
        </Route>
        <Route exact path={"/verifycode/:id"}>
          <VerifyCodePage />
        </Route>
        <Route exact path={"/resetpassword/:id"}>
          <ResetChangePasswordPage />
        </Route>
        <Route exact path={[PATH_FORGOT_PASSWORD]}>
          <ResetPasswordPage />
        </Route>

        <Route exact path={[PATH_SIGNUP_THANKS]}>
          <SignupThanks />
        </Route>
        <Route path={[PATH_Welcome]}>
          <Welcome signup={true} />
        </Route>
        <Route path={[PATH_SIGNUP]}>
          <Signup />
        </Route>

        <div className="page-container">
          <div className="page-header">what's New?</div>
          <Sidebar agent={agent} screenSize={screenSize} activeMenu={activeMenu} handleToggleMenu={handleToggleMenu} />
          <div className={activeMenu ? `${"page-body"}` : "page-body-sm"} onClick={() => screenSize < 768 && setActiveMenu((preValue) => false)} >

            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path={[PATH_SEARCH]}>
              <SearchProperty />
            </Route>
            <Route exact path={[PATH_SHUB]}>
              <ShubPanel />
            </Route>
            <Route exact path={[PATH_MAP]}>
              <Map />
            </Route>
            <Route exact path={[PATH_LISTINGS]}>
              <Listings setPartner={setPartner} partner={partner} agent={agent} profile={user} token={token} signup={false} />
            </Route>
            <Route exact path={[PATH_COLLECTIONS]}>
              <Collections
                user={user}
                profile={user}
                token={token}
                signup={false}
              />
            </Route>
            <Route exact path={[PATH_HOT_DESTINATIONS]}>
              <HotDestinations />
            </Route>
            <Route exact path={[PATH_FAVORITES]}>
              <Favorites />
            </Route>
            <Route exact path={[PATH_RESERVATIONS]}>
              <Reservations />
            </Route>
            <Route exact path={[PATH_REPORTS]}>
              <Reports />
            </Route>
            <Route exact path={[PATH_PROFILE]}>
              <Profile
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_WISH_LIST]}>
              <WishList
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_INTOUCH]}>
              <Touch />
            </Route>
            <Route exact path={PATH_PROPERTY}>
              <Property
                propertyId={propertyId}
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={PATH_PROPERTY_ID}>
              <Property
                propertyId={propertyId}
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_PROPERTY_EDIT]}>
              <PropertyEdit
                propertyId={propertyId}
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_PROPERTY_X]}>
              <Property
                propertyId={propertyId}
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_FAQ]}>
              <Faq />
            </Route>
            <Route exact path={[PATH_PARTNERS]}>
              <Partners
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
            <Route exact path={[PATH_RESERVE]}>
              <PropertyReservePage
                agency={agency}
                agent={agent}
                token={token}
                setProfile={setProfile}
              />
            </Route>
          </div>
        </div>
      </Switch >
      <ToastContainer />
    </>
  );
}
export default MainPage;
