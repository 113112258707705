import React, {useState} from 'react';

const CollectionIcon = props => {
	const {label, path, selected2, selected, pathOver,pathOver2, className, style, onClick} = props;
	const [isHovering, setIsHovering] = useState(false);

	return (
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '94px'}}>
			<img
				onClick={onClick}
				className={className}
				src={isHovering || selected ? pathOver : selected2? pathOver2 :path}
				onMouseOver={() => setIsHovering(true)}
				onMouseOut={() => setIsHovering(false)}
				style={{...style, ...{cursor: 'pointer'}}}
				alt="" />
			{ isHovering && <img src={label} alt="" style={{marginTop: '-5px'}} /> }
		</div>
	)
};


export default CollectionIcon;