import axios from "axios";
import TokenService from "./token.service";
import { baseURL } from "../core";
import { useEffect } from "react";

const userToken = localStorage.getItem("jToken")


const register = (data) => {
    return axios.post("/auth/signup", data);
};


const updateProfilePicture = (data) => {
    const headersForImage = {
        "authorization": `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
    }

    return axios.post(`${baseURL}/agent/update-profile-picture`, data, {
        headers: headersForImage
    }).then((response) => {
        return response.data;
    });
};

const updateTravelAgency = (data, data1) => {
    const headersForImage = {
        "authorization": `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
    }

    return axios.post(`${baseURL}/travel-agency/update-travel-agencies?agency_id=${data1}`, data, {
        headers: headersForImage
    }).then((response) => {
        return response.data;
    });
}

const updateProfileApi = (data1, data2) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/travel-agency/update-travel-agencies?agency_id=${data1}`, data2, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};

const AddNewClientApi = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/client/create-client`, data, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const UpdateClientApi = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/client/update-client`, data, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const VerifyCodeApi = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/agent/verify-code`, data, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};

const ChnagePasswordApi = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/agent/change-password`, data, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};

const GetProfile = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/agent/get-profile?agent_id=${data}`, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const ForgotPasswordApi = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/agent/forget-password`, data, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const GetReservation = (data) => {
    console.log(userToken, "userToken")
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/reservation/get-reservations?reservationID=${data}`, {
        headers: headersForupdate,
    }).then((response) => {
        return response.data;
    });
};

const GetClientCstApi = (name, email) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/client/get-clients?limit=20&search=${name}&email=${email}
    `, {
        headers: headersForupdate,
    }).then((response) => {
        return response.data;
    });
};

const GetSingleClientApi = (agentId, agencyId, clientId) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/client/get-client?agent_id=${agentId}&agency_id=${agencyId}&client_id=${clientId}
    `, {
        headers: headersForupdate,
    }).then((response) => {
        return response.data;
    });
};

const GetWishListLog = () => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/wishlist/get-wishlists`, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const AgentSignup = (data) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/agent/signup`, data, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};

const DestinationsOptions = () => {
    const headersForupdate = {
        "authorization": `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X29iamVjdF9pZCI6Mzk5MTU4NzUsInVzZXJfaWQiOiI0MDY2NTAyMSIsInVzZXJfbmFtZSI6InN5c3RlbStsdW5hLTh5NXljIiwic2NvcGUiOlsiYnJpdm8uYXBpIl0sImlzc3VlZCI6IjE2NzUxMTI3NDYxMzYiLCJleHAiOjE2NzUxMTI4MDYsInNlcnZpY2VfdG9rZW4iOm51bGwsImF1dGhvcml0aWVzIjpbIlJPTEVfU1VQRVJfQURNSU4iLCJST0xFX0FETUlOIl0sImp0aSI6ImVmNzY1MDIyLTZhNzctNGZkMy04Njg1LTFhZTFhZmEzOTJhZSIsImNsaWVudF9pZCI6IjkzOTFlYjVkLWUwNmUtNDY4MS1iNTdhLWQwZTU3NDhhM2RlZSIsIndoaXRlX2xpc3RlZCI6ZmFsc2V9.N9MIeiLyrT3hBUtMJsTvwbYW5Z_o7ZSBuZmir2ytrb8DiE4MoXcmh8C6KriWhmnRqUnSMBRtuLcauVbqjFTorOcWMOd2RQGmisPgXBm1tHT30Hl0i57rQuLZHAVW201ot-TdQwW9oEZ3n2HTGu_A6tRhTizVmG6NRAd5KhOB2_c`,
    }
    return axios.get(`https://api.triangle.luxury/local/destinations`, {
        headers: headersForupdate
    })
        .then((response) => {
            return response.data;
        });
};

const getHotDesinationsApi = () => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.get(`${baseURL}/hotdestination/get-hotdestinations`, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};

const approveAgent = (data, agent_id) => {
    const headersForupdate = {
        "authorization": `Bearer ${userToken}`,
    }
    return axios.post(`${baseURL}/agent/approve-agent?agent_id=${agent_id}`, data, {
        headers: headersForupdate
    }).then((response) => {
        return response.data;
    });
};


const AuthService = {
    GetWishListLog,
    AgentSignup,
    GetReservation,
    register,
    updateProfilePicture,
    updateProfileApi,
    AddNewClientApi,
    GetProfile,
    DestinationsOptions,
    updateTravelAgency,
    GetClientCstApi,
    GetSingleClientApi,
    getHotDesinationsApi,
    approveAgent,
    UpdateClientApi,
    VerifyCodeApi,
    ChnagePasswordApi,
    ForgotPasswordApi
};

export default AuthService;

